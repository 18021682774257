<template>
  <div class="otherbox">
    <div class="bigImg">
    </div>
    <h2>{{data.title}}</h2>
    <span>{{data.createTime}}</span>
    <hr>
    <div v-html="data.content"></div>
  </div>
</template>

<script>
import {noticeDetails} from '../../api/info'
export default {
  name:'NoticeDetails',
  data(){
    return{
      // 父级页面传来的参数
      query:{
        id:'',
      },
      // 获取到的数据
      data:{

      },
    }
  },
  created(){
    // 传参控制显示顶部和底部
    this.$emit('controlShow', false);
    // 获取参数
    this.query.id = this.$route.query.id;
    // 初始化数据
    this.init(this.query);
  },
  methods:{
    init(attr){
      noticeDetails(attr).then(res=>{
        if(res.ok == 1){
          this.data = res.data;
        }
      }).catch(err=>{
        console.log(err);
      })
    }
  }
}
</script>

<style lang="less">
  .otherbox{
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0px 0 50px;
    .bigImg {
      width: 100%;
      height: 600px;
      background: url('../../static/aboutus/banner.jpg') no-repeat;
      background-size: cover;
    }
    h2{
      margin-top: 50px;
    }
    hr{
      width: 950px;
      margin: 20px 0 10px;
    }
    div{
      width: 950px;
      p{
        margin: 20px 0;
        img{
          margin: 0 auto;
        }
      }
    }
    .bottomSpan{
      font-size: 14px;
    }
  }
  @media screen and (max-width: 500px) {
    .otherbox{
      padding: 0px 0 30px;
      .bigImg {
        width: 100%;
        height: 450px;
      }
      h2{
        margin-top: 30px;
        font-size: 22px;
      }
      hr{
        width: 95%;
        margin: 20px 0 10px;
      }
      div{
        width: 95%;
        margin: 0 auto;
        p:nth-child(1){
          font-size: 14px !important;
        }
        p{
          margin: 5px 0;
          img{
            width: 100% !important;
            margin: 0 auto;
          }
        }
      }
      .bottomSpan{
        font-size: 14px;
        margin-top: 20px;
        width: 95%;
        
      }
    }
  }
</style>